import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import labelStyles from '@scss/components/label.scss';

interface ILabel {
  htmlFor?: string;
  children: ReactNode;
  isRequired?: boolean;
  className?: string;
  textTransform?: 'unset' | 'uppercase' | 'lowercase' | 'capitalize';
}

export const Label: FC<ILabel> = ({ htmlFor, isRequired, children, className, textTransform }) => {
  return (
    <>
      <style jsx>{labelStyles}</style>
      <label htmlFor={htmlFor} className={classNames('ks-label', `-${textTransform}`, className)}>
        {children}
        {isRequired && <span className={classNames('ks-label-required', className)}>*</span>}
      </label>
    </>
  );
};

Label.defaultProps = {
  textTransform: 'unset',
  isRequired: false,
};
